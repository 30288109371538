<template>
  <div class="change">
    <div class="change-header is-flex has-content-justified-between">
      <p class="has-margin-bottom-50">
        The following changes were detected on your site:
      </p>
      <p v-if="showViewState" class="has-cursor-pointer" @click="viewState">
        <u>View state</u>
      </p>
    </div>
    <div
      v-for="change in changes"
      :key="change.id"
      class="change-field"
      :class="{
        update: isUpdate(change),
        add: isAdd(change),
        remove: isRemoved(change)
      }"
    >
      <span class="has-margin-right-25">{{
        change.categoryLabel || $_.capitalize(change.status.status)
      }}</span>
      <span>
        <strong>`{{ change.label }}`</strong>
      </span>
      <span>
        <span v-if="isUpdate(change)">
          changed to
          <span class="value"> {{ change.new }}</span>
        </span>
        <span v-else-if="isAdd(change)" class="value">enabled</span>
        <span v-else-if="isRemoved(change)" class="value">disabled</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteChangeItem",
  props: {
    changes: {
      required: true,
      type: Array
    },
    showViewState: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isUpdate(change) {
      return change.new && change.old;
    },
    isAdd(change) {
      return change.new && !change.old;
    },
    isRemoved(change) {
      return !change.new && change.old;
    },
    viewState() {
      this.$modal.open({
        component: () => import("@shared/sites/_siteChangeSnapshotModal.vue"),
        parent: this,
        width: 840,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          title: `State from ${this.$moment(
            this.$_.get(this.changes, "[0].timestamp")
          ).format("DD-MM-YYYY HH:mm")}`,
          siteId: this.$_.get(this.changes, "[0].siteId"),
          changeId: this.$_.get(this.changes, "[0].changeId")
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.change {
  .change-field {
    position: relative;
    font-size: $size-7;
    padding-left: 1rem;
    & + .change-field {
      margin-top: 0.3125rem;
    }
    .value {
      margin-left: 0.2rem;
      padding: 0.2rem 0.4rem;
      border-radius: $radius;
      font-size: $size-8;
      font-weight: $weight-semibold;
    }

    &.update {
      box-shadow: inset 0.25rem 0 0 0 $border;
      .value {
        color: $link;
        background-color: rgba($link, 0.15);
      }
    }

    &.remove {
      box-shadow: inset 0.25rem 0 0 0 $danger;
      .value {
        color: $danger;
        background-color: rgba($danger, 0.15);
      }
    }

    &.add {
      box-shadow: inset 0.25rem 0 0 0 $success;
      .value {
        color: $success;
        background-color: rgba($success, 0.15);
      }
    }
  }
}
</style>
